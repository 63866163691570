import React  from 'react';
import {MODE } from '../controls/Shared';

export default function Header(props) {

    const { user } = props; 

    const authUrl = MODE === "TEST" ? 'https://edge-test.starisland.org/.auth/' : 'https://edge.starisland.org/.auth/';
    const loginUrl = authUrl + "login/aad";
    const logoutUrl = authUrl + "logout";
    

    const userDisplay = user !== null && user !== undefined  ? 
        <div><div style={{fontSize: "35px"}}><label >Welcome {user.userName}</label></div><div style={{fontSize: "35px"}}><a href={logoutUrl}>Logout</a></div></div> 
        : <div style={{fontSize: "35px"}}><a href={loginUrl}>Login</a></div>;
    
    return (
        <div className="App-header">
            <div className="starlogo">
                <a href="https://starisland.org" target="_blank" rel="noreferrer"><img src="https://starisland.org/wp-content/themes/starisland/library/img/star-island-logo.svg" alt="starlogo" /></a>
            </div>   
            <div className="header-title">
                <label className="startext">Oceanic Edge</label>
            </div>            
            <div className="logout">
                {userDisplay}
            </div>                   
        </div>       
        
    )
}